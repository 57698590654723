import {
  Box,
  ResponsiveContext,
  Grid,
  Heading,
  Paragraph,
  Button,
  Image,
} from 'grommet'
import React, { useContext, useEffect } from 'react'
import Logo from '../../assets/vectors/logo.svg'
import Swoosh from '../../assets/vectors/swoosh.svg'
import MainImage from '../../assets/images/bike.png'
import { useResponsive } from '../../hooks'

const CenterBlock = ({
  heading = '',
  image = false,
  content = null,
  swoosh,
  level,
}) => {
  const { isDesktop, size } = useResponsive()
  return (
    <Box
      align="center"
      background="background"
      direction="column"
      justify="end"
      flex={'shrink'}
      margin={{ bottom: 'xlarge' }}
      pad={isDesktop ? "none" : "large"}

    >
      <Box pad={{ bottom: 'small' }}>
        <Heading
          size="xlarge"
          level={level}
          margin={{ bottom: 'medium', top: 'none' }}
        >
          {heading}
        </Heading>
        <Box
          alignSelf="center"
          width="50%"
          border={{ color: 'brand', bottom: { size: '1px' } }}
        />
      </Box>

      {content}

      {image && (
        <Box
          style={{
            position: 'relative',
          }}
        >
          <Box
            fill
            style={{
              height: isDesktop ? '100%' : '50vh',
              position: 'absolute',
            }}
          >
            <Swoosh width="150%" />
          </Box>
          <Box
            style={{
              maxWidth: 600,
              height: isDesktop ? '100%' : '50vh',
              position: 'relative',
              top: 0,
            }}
          >
            <Image fit="cover" src={MainImage} />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CenterBlock

// <Box direction={isDesktop ? 'row-reverse' : 'column'}>
//         <Box
//           flex
//           margin={{
//             bottom: 'large',
//             left: isDesktop ? 'large' : 'none',
//           }}
//           style={{
//             minHeight: isDesktop ? '600px' : '0px',
//             position: 'relative',
//           }}
//         >
//           <Box
//             fill
//             style={{
//               height: isDesktop ? '100%' : '50vh',
//               position: 'absolute',
//             }}
//           >
//             <Swoosh width="150%" />
//           </Box>
//           <Box
//             style={{
//               height: isDesktop ? '100%' : '50vh',
//               position: 'relative',
//               top: 0,
//             }}
//           >
//             <Image fit="cover" src={MainImage} />
//           </Box>
//         </Box>
//         <Box flex margin={{ bottom: 'large' }}>
//           <Heading responsive={false} margin="none" level={1} size={size}>
//             Happy Hour.
//           </Heading>
//           <Heading responsive={false} margin="none" level={1} size={size}>
//             Not Rush Hour.
//           </Heading>
//           <Paragraph margin={{ vertical: 'medium' }}>
//             Our mission is to make your commute fun. We are a new cycle-to-work
//             scheme* that provides a great range of e-bikes along with everything
//             you need for your ride with one affordable monthly costs.
//           </Paragraph>
//           <Button
//             margin={{ top: 'medium' }}
//             alignSelf="start"
//             primary
//             label="find out more"
//           />
//         </Box>
//       </Box>
